"use client"

import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import Link from "next/link"
import { LucideIcon, X } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import Image from "next/image"
import { LOGO } from "@/constants/images"
import { useTheme } from "next-themes"

interface NavItem {
  name: string
  url: string
  icon: LucideIcon
}

interface MobileSidePanelProps {
  items: NavItem[]
  isOpen: boolean
  onClose: () => void
  activeTab: string
  setActiveTab: (name: string) => void
}

export function MobileSidePanel({
  items,
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
}: MobileSidePanelProps) {
  const { theme } = useTheme()

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-background/80 backdrop-blur-sm z-50"
          />
          
          {/* Side Panel */}
          <motion.div
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", damping: 30, stiffness: 300 }}
            className="fixed left-0 top-0 bottom-0 w-[280px] bg-background/5 backdrop-blur-lg z-50 overflow-y-auto"
          >
            {/* Header */}
            <div className="sticky top-0 flex items-center justify-between p-4 border-b border-border/10 bg-background/5 backdrop-blur-lg">
              <div className="relative h-12 w-[180px]">
                <Image
                  src={theme === 'dark' ? `${LOGO.WHITE}.webp` : `${LOGO.DEFAULT}.webp`}
                  alt="Qogent Logo"
                  width={180}
                  height={48}
                  className="h-12 w-auto object-contain select-none"
                  priority
                />
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={onClose}
                className="rounded-full hover:bg-background/10"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            
            {/* Navigation Items */}
            <nav className="p-4 space-y-2">
              {items.map((item) => {
                const Icon = item.icon
                const isActive = activeTab === item.name

                return (
                  <Link
                    key={item.name}
                    href={item.url}
                    onClick={() => {
                      setActiveTab(item.name)
                      onClose()
                    }}
                    className={cn(
                      "relative flex items-center gap-3 px-4 py-3 rounded-full transition-colors",
                      "text-foreground/80 hover:text-primary",
                      isActive && "text-primary"
                    )}
                  >
                    <Icon size={18} strokeWidth={2.5} />
                    <span className="font-medium">{item.name}</span>
                    {isActive && (
                      <motion.div
                        layoutId="mobile-side-active"
                        className="absolute inset-0 bg-primary/5 rounded-full -z-10"
                        initial={false}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                          damping: 30,
                        }}
                      />
                    )}
                  </Link>
                )
              })}
            </nav>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
} 