export const GCS_BASE_URL = 'https://storage.googleapis.com/qogent-images/images';
export const GCS_VIDEO_BASE_URL = 'https://storage.googleapis.com/qogent-images';

export const LOGO = {
  WHITE: `${GCS_BASE_URL}/qogent_logo_white`,
  DEFAULT: `${GCS_BASE_URL}/qogent_logo`,
};

export const TOOLS = {
  DESTINATION_FINDER: `${GCS_BASE_URL}/tools/destination-finder.gif`,
};

export const DESTINATIONS = {
  AUSTRALIA: `${GCS_BASE_URL}/destinations/australia-hero`,
  CANADA: `${GCS_BASE_URL}/destinations/canada-hero`,
  FRANCE: `${GCS_BASE_URL}/destinations/france-hero`,
  GERMANY: `${GCS_BASE_URL}/destinations/germany-hero`,
  IRELAND: `${GCS_BASE_URL}/destinations/ireland-hero`,
  ITALY: `${GCS_BASE_URL}/destinations/italy-hero`,
  JAPAN: `${GCS_BASE_URL}/destinations/japan-hero`,
  NETHERLANDS: `${GCS_BASE_URL}/destinations/netherlands-hero`,
  POLAND: `${GCS_BASE_URL}/destinations/poland-hero`,
  SINGAPORE: `${GCS_BASE_URL}/destinations/singapore-hero`,
  UK: `${GCS_BASE_URL}/destinations/uk-hero`,
  USA: `${GCS_BASE_URL}/destinations/usa-hero`,
  DEFAULT: `${GCS_BASE_URL}/destinations/default-hero`,
};

export const TEAM = {
  MEMBER_1: `${GCS_BASE_URL}/team/member-1`,
  MEMBER_2: `${GCS_BASE_URL}/team/member-2`,
  MEMBER_3: `${GCS_BASE_URL}/team/member-3`,
  TEAM_1: `${GCS_BASE_URL}/team/team-1`,
  TEAM_2: `${GCS_BASE_URL}/team/team-2`,
  MISSION: `${GCS_BASE_URL}/team/mission`,
};

export const BLOG = {
  ACADEMIC_CV: `${GCS_BASE_URL}/blog/academic-cv-guide`,
  AFFORDABLE_COUNTRIES: `${GCS_BASE_URL}/blog/affordable-countries-cost-analysis`,
  BEST_COUNTRIES_2024: `${GCS_BASE_URL}/blog/best-countries-2024-comparison`,
  BEST_COUNTRIES: `${GCS_BASE_URL}/blog/best-countries-international-students`,
  BUILDING_CAREER: `${GCS_BASE_URL}/blog/building-international-career`,
  ENGLISH_SPEAKING: `${GCS_BASE_URL}/blog/english-speaking-countries-education`,
  ENTREPRENEURSHIP: `${GCS_BASE_URL}/blog/entrepreneurship-international-students`,
  FEATURED_GERMANY: `${GCS_BASE_URL}/blog/featured-germany`,
  FIRST_30_DAYS: `${GCS_BASE_URL}/blog/first-30-days`,
  GLOBAL_JOB_MARKETS: `${GCS_BASE_URL}/blog/global-job-markets`,
  GLOBAL_SCHOLARSHIP: `${GCS_BASE_URL}/blog/global-scholarship-guide`,
  GRE_VS_GMAT: `${GCS_BASE_URL}/blog/gre-vs-gmat`,
  IELTS_PREP: `${GCS_BASE_URL}/blog/ielts-preparation-guide`,
  IELTS_VS_TOEFL: `${GCS_BASE_URL}/blog/ielts-vs-toefl-comparison`,
  INTERNATIONAL_BANKING: `${GCS_BASE_URL}/blog/international-student-banking`,
  PORTFOLIO: `${GCS_BASE_URL}/blog/portfolio-development-guide`,
  RECOMMENDATION: `${GCS_BASE_URL}/blog/recommendation-letter-guide`,
  REMOTE_WORK: `${GCS_BASE_URL}/blog/remote-work-opportunities`,
  SCHOLARSHIPS: `${GCS_BASE_URL}/blog/scholarships`,
  SOP_TEMPLATE: `${GCS_BASE_URL}/blog/statement-of-purpose-template`,
  SOP: `${GCS_BASE_URL}/blog/statement-purpose`,
  STUDY_BUDGET: `${GCS_BASE_URL}/blog/study-abroad-budget-template`,
  TOEFL_SUCCESS: `${GCS_BASE_URL}/blog/toefl-success-strategy`,
  WORKING_ABROAD: `${GCS_BASE_URL}/blog/working-while-studying-abroad`,
  DEFAULT: `${GCS_BASE_URL}/blog/default`,
  POSTS: `${GCS_BASE_URL}/blog/posts`,
};

export const UNIVERSITIES = {
  CAMPUS: `${GCS_BASE_URL}/universities/campus`,
  LOGOS: `${GCS_BASE_URL}/universities/logos`,
  DEFAULT_CAMPUS: `${GCS_BASE_URL}/universities/default-campus`,
  DEFAULT_LOGO: `${GCS_BASE_URL}/universities/default-logo`,
};

export const TESTIMONIALS = {
  STUDENT_1: `${GCS_BASE_URL}/testimonials/student-1`,
  STUDENT_2: `${GCS_BASE_URL}/testimonials/student-2`,
  STUDENT_3: `${GCS_BASE_URL}/testimonials/student-3`,
  STUDENT_4: `${GCS_BASE_URL}/testimonials/student-4`,
  DEFAULT: `${GCS_BASE_URL}/testimonials/default`,
  VIDEOS: {
    STUDENT_1: `${GCS_BASE_URL}/testimonials/videos/student-1.webp`,
    STUDENT_2: `${GCS_BASE_URL}/testimonials/videos/student-2.webp`,
    STUDENT_3: `${GCS_BASE_URL}/testimonials/videos/student-3.webp`,
    STUDENT_4: `${GCS_BASE_URL}/testimonials/videos/student-4.webp`,
  }
};

export const FLAGS = {
  BASE: `${GCS_BASE_URL}/flags`,
  DEFAULT: `${GCS_BASE_URL}/flags/default`,
  GERMANY: `${GCS_BASE_URL}/flags/germany.svg`,
  IRELAND: `${GCS_BASE_URL}/flags/ireland.svg`,
  POLAND: `${GCS_BASE_URL}/flags/poland.svg`,
  CANADA: `${GCS_BASE_URL}/flags/canada.svg`,
  USA: `${GCS_BASE_URL}/flags/usa.svg`,
  UK: `${GCS_BASE_URL}/flags/uk.svg`,
  AUSTRALIA: `${GCS_BASE_URL}/flags/australia.svg`,
  FRANCE: `${GCS_BASE_URL}/flags/france.svg`,
  ITALY: `${GCS_BASE_URL}/flags/italy.svg`,
  JAPAN: `${GCS_BASE_URL}/flags/japan.svg`,
  NETHERLANDS: `${GCS_BASE_URL}/flags/netherlands.svg`,
  SINGAPORE: `${GCS_BASE_URL}/flags/singapore.svg`,
};

export const PLACEHOLDERS = {
  HERO: `${GCS_BASE_URL}/placeholders/hero-placeholder`,
  STUDENT_LIFE_1: `${GCS_BASE_URL}/placeholders/student-life-1`,
  STUDENT_LIFE_2: `${GCS_BASE_URL}/placeholders/student-life-2`,
  STUDENT_LIFE_3: `${GCS_BASE_URL}/placeholders/student-life-3`,
  STUDENT_LIFE_4: `${GCS_BASE_URL}/placeholders/student-life-4`,
  TESTIMONIAL_1: `${GCS_BASE_URL}/placeholders/testimonial-1`,
  TESTIMONIAL_2: `${GCS_BASE_URL}/placeholders/testimonial-2`,
  TESTIMONIAL_3: `${GCS_BASE_URL}/placeholders/testimonial-3`,
  AVATAR: `${GCS_BASE_URL}/placeholders/avatar-placeholder`,
  SUCCESS_STORY: `${GCS_BASE_URL}/placeholders/success-story`,
};

export const PARTNERS = {
  DHL: `${GCS_BASE_URL}/partners/logos/dhl`,
  GYANDHAN: `${GCS_BASE_URL}/partners/logos/gyandhan`,
  CORACLE: `${GCS_BASE_URL}/partners/logos/coracle`,
  FINTIBA: `${GCS_BASE_URL}/partners/logos/fintiba`,
  EXPATRIO: `${GCS_BASE_URL}/partners/logos/expatrio`,
  THOMAS_COOK: `${GCS_BASE_URL}/partners/logos/thomascook`,
  REMITOUT: `${GCS_BASE_URL}/partners/logos/remitout`,
  NIYO: `${GCS_BASE_URL}/partners/logos/niyo`,
};

export const SOCIAL = {
  OG_IMAGE: `${GCS_BASE_URL}/social/qogent-social`,
  TWITTER_CARD: `${GCS_BASE_URL}/social/twitter-card`,
  FACEBOOK_COVER: `${GCS_BASE_URL}/social/facebook-cover`,
  LINKEDIN_COVER: `${GCS_BASE_URL}/social/linkedin-cover`,
};

export const VIDEOS = {
  CTA: `${GCS_BASE_URL}/videos/cta-video.mp4`,
  CTA_THUMBNAIL: `${GCS_BASE_URL}/videos/cta-video-thumbnail.webp`,
  TESTIMONIALS: {
    STUDENT_1: `${GCS_BASE_URL}/videos/testimonials/student-1.mp4`,
    STUDENT_2: `${GCS_BASE_URL}/videos/testimonials/student-2.mp4`,
    STUDENT_3: `${GCS_BASE_URL}/videos/testimonials/student-3.mp4`,
    STUDENT_4: `${GCS_BASE_URL}/videos/testimonials/student-4.mp4`,
  },
}; 