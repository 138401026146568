import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/.pnpm/next@15.2.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@15.2.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/opt/build/repo/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/build/repo/src/components/layout/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterPopup"] */ "/opt/build/repo/src/components/newsletter-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollToTop"] */ "/opt/build/repo/src/components/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/opt/build/repo/src/components/theme-provider.tsx");
