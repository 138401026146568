'use client';

import { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { NewsletterSignup } from '@/components/newsletter-signup';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';

interface NewsletterPopupProps {
  delay?: number; // Delay in ms before showing popup
  exitIntent?: boolean; // Show on exit intent
  source?: string;
}

export function NewsletterPopup({
  delay = 60000, // Default 1 minute delay
  exitIntent = true,
  source = 'popup_exit',
}: NewsletterPopupProps) {
  const [open, setOpen] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    // Check if user has already seen the popup before
    const popupSeen = localStorage.getItem('newsletterPopupSeen');
    if (popupSeen) return;

    // Set up the timer for the delay
    let timer: NodeJS.Timeout;
    if (!exitIntent) {
      timer = setTimeout(() => {
        setOpen(true);
        localStorage.setItem('newsletterPopupSeen', 'true');
      }, delay);
    }

    // Exit intent detection
    const handleMouseLeave = (e: MouseEvent) => {
      if (exitIntent && !hasInteracted && e.clientY <= 0) {
        setOpen(true);
        localStorage.setItem('newsletterPopupSeen', 'true');
      }
    };

    // Track user interaction
    const handleInteraction = () => {
      setHasInteracted(true);
    };

    if (exitIntent) {
      document.addEventListener('mouseleave', handleMouseLeave);
      document.addEventListener('click', handleInteraction);
    }

    return () => {
      if (!exitIntent) {
        clearTimeout(timer);
      }
      if (exitIntent) {
        document.removeEventListener('mouseleave', handleMouseLeave);
        document.removeEventListener('click', handleInteraction);
      }
    };
  }, [delay, exitIntent, hasInteracted]);

  // Function to close the dialog and handle the "don't show again" option
  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('newsletterPopupSeen', 'true');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[500px] p-0 bg-background border dark:bg-gray-900/95 backdrop-blur-sm">
        <div className="p-6 relative">
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-2 h-8 w-8 rounded-full"
            onClick={handleClose}
          >
            <X className="h-4 w-4" />
          </Button>
          
          <div className="space-y-6">
            <div className="space-y-3">
              <h2 className="text-2xl font-bold">Last chance...</h2>
              <p className="text-lg text-muted-foreground">
                2,000+ students get our deadline alerts. They're getting scholarships while others miss them.
              </p>
            </div>
            
            <NewsletterSignup
              source={source}
              layout="vertical"
              buttonText="I Don't Want To Miss Out"
              showName={true}
            />
            
            <button
              onClick={handleClose}
              className="w-full text-sm text-muted-foreground hover:underline mt-2"
            >
              I'm okay with missing opportunities
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 